<template>
  <a-card
    class="campaign-performance-trending"
    :widget="true"
    :title="'Timeline'"
  >
    <template #extra>
      <div
        v-if="!isMobileScreen"
        class="trending-representation-selector-wrapper"
      >
        <div class="trending-accumulation-wrapper">
          <a-radio-group
            v-model:value="selectedDataRepresentation"
            class="trending-mode-toggle"
            button-style="solid"
            size="default"
          >
            <a-radio-button value="channel"> Channel</a-radio-button>
            <a-radio-button value="sentiment"> Sentiment</a-radio-button>
          </a-radio-group>
        </div>
      </div>
      <div
        v-else
        class="trending-representation-selector-wrapper"
      >
        <a-dropdown
          placement="bottomRight"
          :getPopupContainer="(trigger) => trigger.parentNode"
        >
          <img :src="require('@/assets/images/icon/icon-sliders.svg')" />
          <template #overlay>
            <a-menu
              id="mode-menu-mobile"
              @click="handleTimelineModeClick"
            >
              <a-menu-item-group title="Measurement:">
                <a-menu-item
                  v-for="mode in modeOptions"
                  :key="mode.value"
                  :style="
                    selectedDataRepresentation === mode.value
                      ? selectedModeStyle
                      : unSelectedModeStyle
                  "
                >
                  {{ mode.label }}
                </a-menu-item>
              </a-menu-item-group>
            </a-menu>
          </template>
        </a-dropdown>
      </div>
    </template>
    <div
      v-if="loading"
      class="loading-wrapper"
    >
      <a-skeleton
        :loading="loading"
        active
      />
    </div>

    <TrendingChart
      v-else-if="!loading && !noData"
      class="chart"
      :chart-type="chartType"
      :series="selectedSerie"
    ></TrendingChart>

    <div
      v-if="!loading && noData"
      class="no-data-wrapper"
    >
      <NoResult></NoResult>
    </div>
  </a-card>
</template>

<script>
import { toRefs, ref, reactive, computed, watch, onBeforeMount } from 'vue';
import { useStore } from 'vuex';

import api from '@/services/api';
import helper from '@/services/helper';
import chartHelper from '@/services/chartHelper';

import TrendingChart from '@/components/Chart/TrendingChart';
import NoResult from '@/components/Error/NoResult.vue';
export default {
  name: 'PerformanceTimeline',
  components: {
    NoResult,
    TrendingChart,
  },
  props: {
    mode: String,
    filterResult: Object,
    campaign: Object,
  },
  setup(props) {
    const { mode, filterResult, campaign } = toRefs(props);
    const store = useStore();
    const isMobileScreen = computed(() => store.state.isMobileScreen);
    const brand = computed(() => store.state.account.brand);

    const sList = api.getAllSource();
    const socialList = reactive(sList);

    const modeOptions = [
      { label: 'Channel', value: 'channel' },
      { label: 'Sentiment', value: 'sentiment' },
    ];

    const selectedModeStyle = {
      color: ' #097cff',
      background: 'rgba(9, 124, 255, 0.05)',
    };

    const unSelectedModeStyle = {
      color: '#5A5F7D',
    };

    const chartType = ref('column');
    let selectedDataRepresentation = ref('channel');
    const seriesData = reactive({
      engagement: [],
      mention: [],
      view: [],
    });
    const selectedSerie = ref([]);
    let loading = ref(false);
    const noData = ref(false);

    const currentGraphCriteria = ref({});
    const drilldownFn = (pointOption, payload = { title: 'data' }) => {
      const { graphTime } = currentGraphCriteria.value;
      const { x } = pointOption;
      const { time, title } = helper.getTimeOpt(x, graphTime);
      let f = {
        payload: {
          title: `${helper.capitalize(payload.title)} ${helper.capitalize(
            title,
          )}`,
        },
        criteria: {
          ...filterResult.value,
          time,
          paging: {
            page: 1,
            recordPerPage: 10,
          },
          sort: {
            direction: 'desc',
            field: 'engagement_score',
          },
          highlight: {
            enable: true,
          },
          source:
            selectedDataRepresentation.value === 'channel'
              ? [payload.title.toLowerCase()]
              : [],
        },
      };
      if (selectedDataRepresentation.value === 'sentiment') {
        f.criteria['sentiment'] = payload.criteria.sentiment;
        f.criteria['source'] = filterResult.value.source;
      }
      if (campaign.value) {
        if (campaign.value.level > 0) {
          f.criteria['subCategory'] = filterResult.value.subCategory;
        } else {
          f.criteria['category'] = filterResult.value.category;
          f.criteria['subCategory'] = [];
        }
      }
      store.dispatch('message/showMessageModal', f);
    };

    const init = async (fv) => {
      loading.value = true;
      if (fv) {
        const { graphCriteria, granularity } = chartHelper.modifyGranularity(
          {},
          fv,
        );
        currentGraphCriteria.value = graphCriteria;
        const filterCriteria = {
          ...fv,
          ...graphCriteria,
        };

        if (selectedDataRepresentation.value === 'channel') {
          const result = await api
            .getCampaignPerformanceChannelTimeline(filterCriteria, brand.value)
            .catch(() => {
              console.error('History api call error', result);
            });
          if (
            result.message &&
            result.message.graphData &&
            Object.keys(result.message.graphData).length !== 0
          ) {
            const newEngagementSeries = [];
            const newCountSeries = [];
            const newViewSeries = [];
            for (let social of socialList) {
              const engagementDataList = [];
              const countDataList = [];
              const viewDataList = [];
              let name = helper.capitalize(social);
              const data = result.message.graphData.data;
              data.forEach((entry) => {
                let x = entry.date;
                if (entry.source != null) {
                  let yEngagement = entry.source.engagement[social];
                  engagementDataList.push([x, yEngagement]);
                  let yCount = entry.source.count[social];
                  countDataList.push([x, yCount]);
                  let yView = entry.source.view[social];
                  viewDataList.push([x, yView]);
                }
              });
              const pl = {
                title: name,
                criteria: {},
              };
              pl.criteria[name] = [name];
              newEngagementSeries.push({
                name: name,
                color: helper.getColorChannel(social),
                data: engagementDataList,
                point: {
                  events: {
                    click: (e) => drilldownFn(e.point.options, pl),
                  },
                },
                custom: {
                  granularity,
                },
              });
              newCountSeries.push({
                name: name,
                color: helper.getColorChannel(social),
                data: countDataList,
                point: {
                  events: {
                    click: (e) => drilldownFn(e.point.options, pl),
                  },
                },
                custom: {
                  granularity,
                },
              });
              newViewSeries.push({
                name: name,
                color: helper.getColorChannel(social),
                data: viewDataList,
                point: {
                  events: {
                    click: (e) => drilldownFn(e.point.options, pl),
                  },
                },
                custom: {
                  granularity,
                },
              });
            }
            seriesData.engagement = newEngagementSeries;
            seriesData.mention = newCountSeries;
            seriesData.view = newViewSeries;

            selectedSerie.value = seriesData[mode.value];

            // check no data
            checkSelectedSerieData();
          } else {
            let arr = [];
            seriesData.engagement = arr;
            seriesData.mention = arr;
            seriesData.view = arr;
            selectedSerie.value = seriesData[mode.value];

            noData.value = true;
          }
        }
        if (selectedDataRepresentation.value === 'sentiment') {
          const qs = reactive({});
          const result = await api
            .getSentimentHistoryGraph(filterCriteria, qs)
            .catch(() => {
              console.error('History api call error', result);
            });
          let newEngagementSeries = [];
          let newCountSeries = [];
          let newViewSeries = [];
          let dataCount = {
            yEngagement: 0,
            yCount: 0,
            yView: 0,
          };
          if (result.message != null && result.message.graphData != null) {
            const graphData = result.message.graphData;
            let count = 0;
            for (const [sentimentType, sentimentData] of Object.entries(
              graphData,
            )) {
              const data = sentimentData.data;
              if (data != null) {
                const engagementDataList = [];
                const countDataList = [];
                const viewDataList = [];
                data.forEach((entry) => {
                  let x = entry.x;
                  if (entry.y != null) {
                    let yEngagement = entry.y.engagement;
                    engagementDataList.push([x, yEngagement]);
                    let yCount = entry.y.count;
                    countDataList.push([x, yCount]);
                    let yView = entry.y.view;
                    viewDataList.push([x, yView]);

                    if (yEngagement > 0) dataCount.yEngagement += 1;
                    if (yCount > 0) dataCount.yCount += 1;
                    if (yView > 0) dataCount.yView += 1;
                  }
                });
                let name = helper.capitalize(sentimentType);
                const color = chartHelper.getColor(
                  sentimentType.toLowerCase(),
                  count,
                );
                count++;
                const pl = {
                  criteria: { sentiment: [sentimentType] },
                  title: `${name} sentiment`,
                };
                if (name) {
                  if (dataCount.yEngagement === 0) {
                    newEngagementSeries = [];
                  } else {
                    newEngagementSeries.push({
                      name: name,
                      color: color,
                      data: engagementDataList,
                      point: {
                        events: {
                          click: (e) => drilldownFn(e.point.options, pl),
                        },
                      },
                      custom: {
                        granularity,
                      },
                    });
                  }

                  if (dataCount.yCount === 0) {
                    newCountSeries = [];
                  } else {
                    newCountSeries.push({
                      name: name,
                      color: color,
                      data: countDataList,
                      point: {
                        events: {
                          click: (e) => drilldownFn(e.point.options, pl),
                        },
                      },
                      custom: {
                        granularity,
                      },
                    });
                  }

                  if (dataCount.yView === 0) {
                    newViewSeries = [];
                  } else {
                    newViewSeries.push({
                      name: name,
                      color: color,
                      data: viewDataList,
                      point: {
                        events: {
                          click: (e) => drilldownFn(e.point.options, pl),
                        },
                      },
                      custom: {
                        granularity,
                      },
                    });
                  }
                }
              }
            }
            chartType.value = 'column';
            seriesData.engagement = newEngagementSeries;
            seriesData.mention = newCountSeries;
            seriesData.view = newViewSeries;

            selectedSerie.value = seriesData[mode.value];
            checkSelectedSerieData();
          } else {
            let arr = [];
            seriesData.engagement = arr;
            seriesData.mention = arr;
            seriesData.view = arr;

            selectedSerie.value = seriesData[mode.value];
            noData.value = true;
          }
        }
      }
      loading.value = false;
    };

    const checkSelectedSerieData = () => {
      if (selectedSerie.value && selectedSerie.value.length > 0) {
        noData.value = false;
      } else {
        noData.value = true;
      }
    };

    const selectedCategoryLevelStyle = {
      'background-color': '#f2f7fe',
      color: '#337df6',
    };

    const unselectedCategoryLevelStyle = {
      color: '#868EAE',
    };

    const handleTimelineModeClick = async (e) => {
      selectedDataRepresentation.value = e.key;
    };

    watch(
      () => selectedDataRepresentation.value,
      () => {
        init(filterResult.value);
      },
    );

    watch(
      () => mode.value,
      () => {
        selectedSerie.value = seriesData[mode.value];
        checkSelectedSerieData();
      },
    );

    watch(
      () => filterResult.value,
      () => {
        init(filterResult.value);
      },
    );

    onBeforeMount(() => {
      init(filterResult.value);
    });

    return {
      chartType,
      selectedSerie,
      loading,
      noData,
      isMobileScreen,
      selectedCategoryLevelStyle,
      unselectedCategoryLevelStyle,
      selectedDataRepresentation,
      modeOptions,
      selectedModeStyle,
      unSelectedModeStyle,
      handleTimelineModeClick,
      seriesData,
    };
  },
};
</script>

<style lang="scss">
@import '../../../config/theme/colors.json';
.campaign-performance-trending {
  .mode-dropdown-mobile {
    width: 104px;
    height: 100px;
  }
  .button-primary-light {
    /* TODO Refactor to outline btn */
    background: #2c99ff15;
    border-width: 0px;
    color: $primary-color;
    &:focus {
      background: #2c99ff15;
      border-width: 0px;
      color: $primary-color;
    }
  }

  .trending-representation-selector-wrapper {
    display: flex;
    justify-content: flex-end;
    margin: 12px 0;
  }
  @media screen and (max-width: 560px) {
    .trending-representation-selector-wrapper {
      margin-bottom: 0;
      padding: 15px 0;
    }
  }
  .trending-accumulation-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-end;
    row-gap: 16px;
    column-gap: 16px;
    .trending-mode-toggle {
      .ant-dropdown-trigger {
        line-height: 30px;
        margin: 0;
      }
    }
  }

  .loading-wrapper,
  .no-data-wrapper {
    min-height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .ant-card-head .ant-card-head-wrapper {
    flex-flow: row;
    align-items: center;
  }

  .ant-card-head-wrapper .ant-card-extra {
    padding: 0px !important;
    display: block;
  }
  @media screen and (max-width: 560px) {
    .ant-card-head-wrapper .ant-card-extra {
      display: flex;
      justify-content: flex-end;
      flex-direction: row;
      column-gap: 12px;
    }
  }
  .ant-dropdown {
    .ant-dropdown-menu {
      min-width: 200px;
      border-radius: 0 0 5px 5px;
      box-shadow: 0 0;
    }
  }

  .chart-type {
    width: fit-content;
    margin-left: auto;
    padding: 15px 0;
    line-height: 1.4;
  }

  @media screen and (max-width: 560px) {
    .chart-type {
      margin-left: 0;
      justify-content: flex-end;
    }
  }

  .more-menu {
    color: #9299b8;
  }

  .mode-mobile {
    width: 104px;
    height: 100px;
    box-shadow: 0px 5px 20px rgba(146, 153, 184, 0.2);
    border-radius: 6px;
  }
}
</style>
